*, 
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Arial, sans-serif; 
    background-color: var(--color-background);
    font-size: 16px;
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
    -ms-text-size-adjust: 100%;
}

/* Set default margin and padding to 0 */
body,
h1,
h2,
h3,
p,
ol,
ul,
li,
a,
button {
    margin: 0;
    padding: 0;
}

.App {
  width:100%;
  height: 100%;

  /* Purple colors */
  --light-purple: rgb(187, 187, 252);
  --medium-purple: rgb(142, 142, 255);
  --dark-purple: rgb(82 82 248);
  --darker-purple: rgb(47, 47, 228);

  /* Gray colors */
  --light-gray: rgb(240, 240, 240);
  --medium-gray: rgb(212, 212, 212);
  --dark-gray: rgb(128, 128, 128);

  /* Error colors */
  --error: rgb(255, 0, 0);
  --error-light: rgb(255 205 205);

  /* White color */
  --white: rgb(255, 255, 255);

  --typeform-width: 550px;
  --typeform-height: 500px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 45px;
}

.translucent-button {
    border: 1px solid var(--dark-purple);
    height: fit-content;
    padding: 10px;
    border-radius: 3px;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    cursor: pointer;
    font-weight: bold;
    font-size: .75rem;
}

.translucent-button:hover {
    background-color: var(--medium-purple);
}

.header .logo-container {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.header .logo-container h1 {
    margin-left: 30px;
    font-size: 1.5rem;
}
.App h1 {
    font-size: 2rem;
}
.App h1, .loading-bar {
    text-align: center;
}

.title-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    padding: 0 2rem;
}

.main-title {
    margin-bottom: 1rem;
    text-align: center;
}

.main-area {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 3rem;
}

.step-area {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    max-width: 45rem;
    max-height: 32rem;
}


.step-area-checkout-form {
    height: fit-content;
    max-height: fit-content;
    width: 100%;
}

.upload-button,
.download.step-area pre {
    width: 500px;
    height: 325px;
}

.describe.step-area .typeform {
    width: var(--typeform-width);
    height: var(--typeform-height)
}

/* Steps Tracker */
.steps-tracker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 16rem;
    margin: 4rem;
}

/* File Upload */
.upload-button {
    border-radius: 3px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--light-gray);
    border: 1px dashed var(--dark-gray);
    cursor: pointer;
}
.upload-button svg {
    margin-top: 40px;
}
.upload-button:hover {
    background-color: var(--medium-gray);
}

/* When something is dragged, set the upload button background */
.dragging > .upload-button {
    background-color: var(--medium-gray);
}

.upload-error-message,
.conversion-error-message {
    color: var(--error);
    font-size: 1.5rem;
    border: 1px solid var(--error);
    border-radius: 5px;
    background-color: var(--error-light);
    padding: 1rem;
    height: var(--typeform-height);
    width: var(--typeform-width);
    margin-top: 1rem;
}

/* Loading Bar */
.loading-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    align-items: center;
    bottom: 0;
    padding: 21px;
    border-top: 1px solid var(--dark-gray);
    background-color: var(--white)
}

.linear-progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 1em;
    color: var(--dark-purple);
    background-color: var(--light-purple);
    font-size: 2rem;
    width: 90vw;
    margin-top: 10px;
}

.linear-progress::-webkit-progress-bar {
    background-color: transparent;
}

.linear-progress:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
    animation: linear-progress 2s infinite linear;
    border-radius: 5px;
}

.linear-progress:indeterminate::-moz-progress-bar {
    background-color: transparent;
}

.linear-progress:indeterminate::-ms-fill {
    animation-name: none;
}

@keyframes linear-progress {
    0% {
        background-size: 200% 100%;
        background-position: left -31.25% top 0%;
    }
    50% {
        background-size: 800% 100%;
        background-position: left -49% top 0%;
    }
    100% {
        background-size: 400% 100%;
        background-position: left -102% top 0%;
    }
}

/* Examples */
.example-buttons-container p {
    margin-top: 2.5rem;
    font-size: 1rem;
}
.example-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
.example-button {
    border: none;
    background: var(--light-purple);
    border-radius: 4px;
    font-size: 1rem;
    position: relative;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}
.example-button > button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px;
}
.example-button > button.example-download-button {
    border-left: 1px solid var(--medium-gray);
}
.example-button > button:hover {
    background-color: var(--medium-purple);
}

/* Download Step */

.download.step-area .download-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}

.download.step-area button {
    margin-top: 1rem;
    border: none;
    color: var(--white);
    background-color: var(--dark-purple);
    padding: 14px;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
}

.load-balance.step-area button {
    margin-top: 1rem;
    border: none;
    color: var(--white);
    background-color: var(--dark-purple);
    padding: 14px;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
}


.load-balance.step-area input {
    margin-top: 1rem;
    padding: 14px;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 4px;
    border-color: var(--dark-purple);
    cursor: pointer;
    align-self: center;
    margin-right: 2rem;
}

.load-balance h2 {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: normal;
}


.download.step-area button + button {
    margin-left: 1rem;
}

.download.step-area button:hover {
    background-color: var(--darker-purple);
}



/** Mobile Styles **/
@media only screen and (max-width: 950px) {
    .main-area {
        flex-direction: column;
        align-items: center;
    }

    .hide_on_small_screen {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        padding: 20px 10px;
        justify-content: center;
    }

    .translucent-button {
        display: none;
    }

    .header .logo-container h1 {
        margin-left: 10px;
        font-size: 1.5rem;
    }

    .title-card {
        margin-top: 2rem;
        text-align: center;
    }

    .main-area {
        margin-top: 2.5rem;
        flex-direction: column;
        align-items: center;
    }

    .step-area {
        max-width: 90%;
        align-items: center;
    }

    .upload-button,
    .download.step-area pre {
        max-width: 90%;
        height: 250px;
    }

    .describe.step-area .typeform {
        max-width: 100%;
        height: 300px;
    }

    .steps-tracker {
        margin: 2rem;
    }

    .upload-error-message,
    .conversion-error-message {
        width: 90%;
        height: fit-content;
    }

    .App .main-title {
        font-size: 1.5rem;
    }

    .loading-bar {
        padding: 10px;
    }

    .linear-progress {
        width: 90%;
    }

    .example-buttons-container p {
        margin-top: 1.5rem;
    }

    .example-buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .example-button {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .download.step-area button {
        padding: 10px;
        bottom: 20px;
    }

}