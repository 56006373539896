p, a {
    font-size: 1.5rem;
}

a {
    text-decoration: none;
    color: var(--dark-purple)
} 

.text-highlight {
    color: var(--dark-purple);
}