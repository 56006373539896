.steps-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: inherit;
    white-space: nowrap;
}

.steps-display p {
    color: var(--dark-gray);
    user-select: none;
    margin: .5rem 1.5rem;
    font-size: 1.25rem;

}

.steps-display input[type='checkbox'] {
    accent-color: var(--light-purple);
    height: fit-content;
    box-shadow: 0px 2px 2px var(--light-purple);
}

.steps-display.active-step p {
    color: black;
}

.step-status-error {
    color: var(--error);
    margin: 0 8px;
}

.steps-display .step-description-error {
    color: var(--error);
}

.steps-display .step-description-complete {
    color: black;
}

@media only screen and (max-width: 600px) {
    .steps-display p {
        font-size: 1rem;
    }
    
    .steps-display input[type='checkbox'] {
        height: 1rem;
    }
}



